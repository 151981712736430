<template>

  <div class="group-tag-page" @click.stop="cklickModal(false)" v-show="tagModalShow">
      <div @click.stop="cklickModal(true)" class="group-tag-page-center">
            <div class="group-tag-detail-list">
                <!-- 添加标签 -->
                <div class="group-tag-detail-name">
                    {{ $getStringObj.getString($Strings.Mind_Edit_Add)+$getStringObj.getString($Strings.Mind_Group_Label) }}
                    <div class="close-setting">
                        <img class="close-button-img" src="../../assets/img/popupsImg/colse_new.svg"  @click.stop="cancel()" />
                    </div>
                </div>
                <section class="label-box">
                    <div class="label-item" style="margin-top:8px;">
                        <!-- 默认标签 -->
                        <div class="label-comment-text">
                            <span>{{
                                $getStringObj.getString($Strings.Default_Label)
                            }}：</span>
                        </div>
                        <div class="label-content">
                            <div class="default-label-item" v-for="(item,index) in defaultLabelData" :key="index"
                             :class="{'selected-default-label':selectedDefaultLabel.includes(index)}" @click="selectedLabe(index)">
                                {{item}}
                            </div>
                        </div>
                    </div>
                    <div class="label-item" style="align-items:center;">
                        <!-- 自定义标签 -->
                        <div class="label-comment-text" style="margin-top:0;">
                            <span>{{
                                $getStringObj.getString($Strings.Mind_Group_Custom_Tag)
                            }}：</span>
                        </div>
                        <input
                        class="group-tag-detail-input"
                        v-model="groupTagName"
                        @input="inputArea()"
                        v-on:keyup.enter="affirm()"
                        :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name)"
                        >
                    </div>
                    <div class="label-item" style="margin-top:8px;">
                        <!-- 已选标签 -->
                        <div class="label-comment-text">
                            <span>{{
                                $getStringObj.getString($Strings.Selected_Label)
                            }}：</span>
                        </div>
                        <div class="label-content">
                            <div class="selected-label-item" v-for="(item,index) in selectedLabelData" :key="index" @click.stop="cancelSelectedLabe(index)">
                                <span>{{item}}</span>
                                <img class="selected-close-img" src="../../assets/img/popupsImg/colse_new.svg" />
                            </div>
                        </div>
                    </div>
                    <div class="tag-warning">
                        {{warning}}
                    </div>
                </section>
            </div>
            <div class="group-tap-footer">
                <div @click.stop="cancel()" class="group-tap-footer-button button-class-colse">
                    {{ $getStringObj.getString($Strings.Mind_Group_Cancel) }}
                </div>
                <div @click.stop="affirm()" class="group-tap-footer-button button-class-ok">
                    {{ $getStringObj.getString($Strings.Mind_Group_Confirm) }}
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { postGroupGetGtag,postGroupRecommendGtags } from "../../common/netWork/group_api";
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
export default {
     props: [
        "show",
        "defaultLabelList"
    ],
    data() {
        return {
            tagModalShow: false,
            groupTagName:'',
            confrim: false,
            warning: '',
            defaultLabelData:[],//默认标签
            selectedLabelData:[],//已选标签
            selectedDefaultLabel:[],//选中默认标签
        }
    },
    created(){
        this.tagModalShow = this.show
        let groupDataDetail = this.$store.state.groupDataDetail
        if (groupDataDetail != null) {
            this.getTags(groupDataDetail.id)
        }else{
            this.defaultLabelData = this.defaultLabelList;
        }
        // console.log(this.defaultLabelData);
    },
    methods: {
        pullGroupTag(obj) {
            return new Promise((resolve, reject) => {
                postGroupGetGtag(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        getTags(groupId){
            ajaxPromise(postGroupRecommendGtags,{groupId},this)
              .then((res) => {
                if (!!res) {
                    let defaultTags = res.tags;//默认标签
                    if (!!defaultTags && defaultTags.length > 0) {
                        // let len = defaultTags.length;
                        // if (len > 5) {
                        //     defaultTags.length = 5 
                        // }
                        this.defaultLabelData = defaultTags;
                    }
                }
            })
        },
        cklickModal(val) {
            this.tagModalShow = val;
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, confrim: this.confrim, groupTagName: this.trim(this.groupTagName)})
                this.groupTagName = ''
                this.warning = ''
            }
        },
        hiddenTap(obj) {
            this.$emit("hiddenTap", obj);
        },
        affirm() {
            if (this.trim(this.groupTagName) == '' && this.selectedLabelData.length==0) {
                this.warning = this.$getStringObj.getString(this.$Strings.Mind_Group_The_Content_Cannot_Be_Empty)
                return
            }
            this.confrim = true;
            this.$emit("selectedLabeList", this.selectedLabelData);
            this.selectedLabelData.forEach(item =>{
                this.pullGroupTag({ name: item }).then(res=> {});
            })
            this.pullGroupTag({ name: this.trim(this.groupTagName) }).then(res=> {
                this.cklickModal(false);
                this.confrim = false;
            });
        },
        cancel() {
            this.confrim = false;
            this.cklickModal(false);
        },
        trim(str) { //判断有首尾空格去除空格
            return str.replace(/^\s+|\s+$/g, '');
        },
        inputArea() {   //不允许输入或黏贴超过20个字符
            this.warning =''
            if (this.groupTagName.length > 20){
                this.groupTagName = this.groupTagName.slice(0,20);
            }
        },
        selectedLabe(index){
            this.warning =''
            this.selectedDefaultLabel.push(index);
            this.selectedLabelData.push(this.defaultLabelData[index]);
            this.selectedDefaultLabel=Array.from(new Set(this.selectedDefaultLabel))//去掉重复项
            this.selectedLabelData=Array.from(new Set(this.selectedLabelData))//去掉重复项
        },
        cancelSelectedLabe(index){
            this.selectedDefaultLabel.splice(index,1);
            this.selectedLabelData.splice(index,1);
        },
        
    },
    watch: {
        show: {
            handler(newval, olval) {
                
            },
            deep: true,
        },
        groupTagName: {
            handler(newval, olval) {

            },
        },
        
    }
}
</script>

<style lang="less" scoped>
    .group-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-tag-page-center {
            position: absolute;
            width: 560px;
            min-height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .group-tag-detail-list {
                width: 100%;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 30px;
                .group-tag-detail-name {
                    // margin-right: 20px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                .close-setting{
                    position: relative;
                    float: right;
                }
            
                .close-button-img{
                    width:18px;
                    cursor: pointer;
                    opacity: 0.5;
                }
                .close-button-img:hover{
                    opacity: 0.8;
                }
                .label-box{
                    width: 100%;
                    padding: 0 20px;
                    .label-item{
                        width: 100%;
                        min-height: 40px;
                        display: flex;
                        // align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .label-comment-text{
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            line-height: 20px;
                            color: #333333;
                            margin-top: 12px;
                        }
                        .group-tag-detail-input {
                            width: 422px;
                            height: 32px;
                            padding-left: 10px;
                            border: 0;
                            background-color: #F5F5F5;
                            outline: none;
                            border-radius: 2px;
                            font-size: 12px;
                        }
                        .label-content{
                            width: 422px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            // justify-content: space-between;
                            .default-label-item{
                                min-width: 50px;
                                height: 32px;
                                line-height: 32px;
                                cursor: pointer;
                                font-size: 14px;
                                color: #999;
                                border-radius: 4px;
                                text-align: center;
                                margin-right: 16px;
                                margin-top: 12px;
                                padding: 0 12px;
                                background: #F1F1F1;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            .selected-default-label {
                                color: #FF765E;
                                background: #FFE4DF !important;
                            }
                            .selected-label-item{
                                height: 28px;
                                line-height: 28px;
                                cursor: pointer;
                                background: #ffe4df;
                                padding: 0 12px;
                                border-radius: 30px;
                                margin-top: 12px;
                                margin-right: 16px;
                                position: relative;
                                font-size: 14px;
                                color: #EC705A;
                                display: flex;
                                align-items: center;
                                justify-content: space-evenly;
                                &:last-child{
                                    margin-right: 0;
                                }
                                .selected-close-img{
                                    width:16px;
                                    margin-left: 8px;
                                    cursor: pointer;
                                    opacity: .3;
                                }
                            }
                        }

                    }
                    .tag-warning {
                        // text-align: center;
                        color: #FD492B;
                    }
                }
                
            }
            .group-tap-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                .group-tap-footer-button {
                    padding: 0 24px;
                    margin: 0 40px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 2px;
                    font-size: 16px;
                    // color: #999999;
                    // border: 1px solid #EEEEEE;
                    // background-color: #F0F2F8;
                }
                .button-class-ok{
                    background: #FD492B;
                    color: #fff;
                }
                .button-class-colse{
                    border: 1px solid #F0F2F8;
                    color: #999999;
                }
                .button-class-colse:hover {
                    color: #FD492B;
                    border: 1px solid #FD492B;
                    background-color: #fff;
                }
                .button-class-ok:hover{
                    background: #ff7354;
                }
            }
        }
    }
</style>