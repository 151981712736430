<template>
    <div @click.stop="cklickModal(false)" v-show="imgShow" class="header-img-box">
        <div @click.stop="cklickModal(true)" class="header-img-center">
            <div @click.stop="cklickModal(false)" class="header-close-modal">
                <a-icon type="close" />
            </div>
            <div class="header-img-title">
                {{ getString(Strings.Mind_Group_Picture_Upload) }}
            </div>
            <div class="header-img-conetnt">
                <img v-for="(item,index) in defaultHeaderList" :key="index" class="header-img-child" @click.stop="selectedImg(item)" :src="httpImageToPrefix(item)" alt="" />
            </div>
            <div @click="clickUpload" class="header-img-title img-header-file-upload">
                {{ getString(Strings.Mind_Group_Upload_From_Local) }}
            </div>
            <input
            type="file"
            accept="image/*"
            @change="handleFile"
            class="hiddenInput"
            />
        </div>
        <div @click.stop="cklickModal(true)" >
            <CropperContentCommon :showCropper="isShowCropper" :imgWidth="imgWidth" :imageUrl="imageUrl" :imgHeight="imgHeight" @hiddenCropper="hiddenCropper"  />
            <SpinningAnimation :spinning="spinning" />
        </div>

    </div>  
</template>

<script>
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import Strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import CropperContentCommon from "../../common/CropperContent/CropperContentCommon";
import SpinningAnimation from"../../spinningAnimation/SpinningAnimation";
export default {
    props: [
        "show",
        "defaultHeaderList"
    ],
    components: {
        CropperContentCommon,
        SpinningAnimation
    },
    data() {
        return {
            imgShow: false,
            imgSrc: '',
            Strings:Strings,
            isShowCropper: false, //是否展示模态框
            imageUrl: '', //图片地址等
            imgWidth: 0, 
            imgHeight: 0,
            spinning: false, //加载动画
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        hiddenCropper(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.isShowCropper = false;
                    if (obj.confirm) {
                        this.sendingPictures(obj.imgData);
                    }
                    
                    // if (obj.groupTagName != '') {
                    //     this.addGroupTag(obj.groupTagName);
                    // }
                }
            }
        },

        getString(i) {
            return getString(i);
        },
        handleFile(e) {
            let $target = e.$target || e.srcElement;
            let file = $target.files[0];
            var reader = new FileReader();
            reader.onload = (data) => {
                let res = data.target || data.srcElement;
                let userImage = res.result;
                // this.showCropperImgUrl({ img: userImage, type: "uploadImg" });
                this.isShowCropper = true;
                this.imageUrl = userImage;
                var img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                img.src = e.target.result;
                img.onload = () => {
                    this.imgWidth = img.width;
                    this.imgHeight = img.height;
                };
            };

            reader.readAsDataURL(file);
            if (file < 1024 * 1024 * 3) {
                this.$message.error(
                getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
                );
                return false;
            } else {
                // this.showCropperImg(true);
                // this.$el.querySelector(".hiddenInput").value = null;
            }

            
        },

        sendingPictures(blob) {

            this.spinning = true;
            let avatar = new Promise((resolve) => {
              if (blob.type.indexOf("image/") > -1) {
                postMindmapUploadImage(
                  blob,
                  (res) => {
                    resolve(res);
                  },
                  (error) => {
                    this.$message.error(error.desc);
                  },
                  null,
                  {
                    "X-Type":"group-cover",
                    "X-ID":"-"
                  }
                );
              }
            });
            avatar.then((res) => {
                this.imgSrc = res.imageUrl
                this.cklickModal(false);
            }).finally(res => {
                this.spinning = false
            })



        },

        clickUpload(){
            this.$el.querySelector(".hiddenInput").click();
        },

        httpImageToPrefix(src) {
            return httpImageToPrefix(src)
        },

        cklickModal(val) {
            this.imgShow = val;
            if (val == false) { //当隐藏模态框时
                if(this.imgSrc == '') {
                    this.imgSrc = this.defaultHeaderList[0]
                }
                this.hiddenTap({show:false, src: this.imgSrc})
            }
        },

        hiddenTap(obj) {
            this.$emit("hiddenImageModal", obj);
        },

        selectedImg(src) {
            this.imgSrc = src;
            this.cklickModal(false)
        },

    },

    watch: {
        show: {
            handler(newval, olval) {
                this.imgShow = newval
            },
            deep: true,
        }
    }

}
</script>

<style lang="less">
    .header-img-box {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .header-img-center {
            position: absolute;
            width: 570px;
            min-height: 370px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .hiddenInput {
                display: none;
            }
            .header-close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .header-close-modal:hover {
                background-color: #F2F2F2;
            }

            .header-img-title {
                margin: 0 auto;
                margin-top: 30px;
                margin-bottom: 20px;
                text-align: center;
                font-size:16px;
                color: #666;
            }
            .header-img-conetnt {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin: 0 auto;
                width: 420px;
                .header-img-child {
                    display: block;
                    width: 88px;
                    height: 88px;
                    margin: 10px 20px;
                    cursor: pointer;
                }
            }
            .img-header-file-upload {
                cursor: pointer;
            }
        }
    }
</style>