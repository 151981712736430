<template>
    <div  class="group-created-new-group">
        <div @click.stop="hidden(true)" class="center-writer">
            <div @click="hidden()" class="close-modal">
                <a-icon type="close" />
            </div>
            <div v-if="userImage == ''" @click="UpUserHeader" class="group-add-header">
                <div class="level-line">
                </div>
                <div class="vertical-line"></div>
            </div>
            <div v-else @click="UpUserHeader" :style="{ backgroundImage: 'url('+ httpImageToPrefix(userImage) +')', }" class="group-add-header group-header-img">
            </div>

            <div>
                <div class="group-detail-list">
                    <label >
                    <span class="group-detail-name">
                        {{ $getStringObj.getString($Strings.Mind_Group_Name) }}
                    </span>
                    <input style="width:100%"
                    class="group-detail-input"
                    v-model="groupName"
                    @input="inputArea()"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Enter_A_Shared_Group_Name)"
                    >
                    </label>
                    <div class="group-name-warning">
                        {{groupNameWarning}}
                    </div>
                </div>
                <div class="group-detail-list">
                    <label >
                    <span class="group-detail-name">
                        {{ $getStringObj.getString($Strings.Mind_Group_Introduction) }}
                    </span>
                    <input style="width:100%"
                    class="group-detail-input"
                    v-model="groupIntroduce"
                    @input="inputIntroduce()"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups)"
                    >
                    </label>
                </div>
                <div class="group-tag">
                    <div class="group-tag-name">{{ $getStringObj.getString($Strings.Mind_Group_Shared_Group_Tag) }}</div>
                    <div class="group-tag-right">
                        <div class="group-my-tag-list">
                            <div v-for=" (item,index) in myTagList" :key="index" @click="removeGroupTag(item,index)" class="group-my-tag-button">
                                <span  class="group-my-tag-button-text">{{ item }}</span>
                                  <!-- <span class="cleariconfont group-my-tag-button-icon">&#xe605;</span> -->
                                <img class="label-close-img" src="../../assets/img/popupsImg/colse_new.svg" />
                            </div>
                            <div @click="clickCreatedTag" class="group-all-tag-button">
                                + {{ $getStringObj.getString($Strings.Mind_Group_Custom_Tag) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="check-big-box">
                    <div class="check-big-box-child">
                        <span class="check-big-box-child-text">
                           {{ $getStringObj.getString($Strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added) }}
                        </span>
                         <a-switch size="small" @click="checkSwitch('groupNumber')"  :checked="groupNumber"  default-checked />
                    </div>
                    <div class="check-big-box-child">
                        <span class="check-big-box-child-text">
                            {{ $getStringObj.getString($Strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining) }}
                        </span>
                         <a-switch @click="checkSwitch('addGroupMessage')" :checked="addGroupMessage" size="small" default-checked />
                    </div>
                </div>
                <div class="storm-big-box">
                    <!--  是否分享组到风暴  -->
                    <div class="storm-big-box-child">
                        <span class="storm-big-box-child-text">
                           {{ $getStringObj.getString($Strings.Share_Group_To_Storm) }}
                        </span>
                         <a-switch size="small" @click="checkSwitch('groupToStorm')"  :checked="groupToStorm"  default-checked />
                    </div>
                    <!-- 付费加入 -->
                    <div class="storm-big-box-child">
                        <span class="storm-big-box-child-text">
                            {{ $getStringObj.getString($Strings.Pay_To_Goin) }}
                            <span><a class="storm-text-link" href="" @click.prevent="seeExplain">{{ $getStringObj.getString($Strings.Mind_See) }}</a> </span>
                        </span>
                        <div class="m-bean-box">
                            <span class="m-bean-text"> M </span>
                            <img src="../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:0 8px 0 4px;">
                            <a-select v-model="mBeanNumber" style="width: 102px" @change="mBeanHandleChange" :disabled="!payToGoin">
                                <a-select-option v-for="item of mBeanList" :key="item.value" :value="item.value">
                                {{item.value}}
                                </a-select-option>
                            </a-select>
                        </div>
                         <a-switch @click="checkSwitch('payToGoin')" :checked="payToGoin" size="small"/>
                    </div>
                </div>
                <div class="group-save-button-box">
                    <div @click="saveGroupData()" class="group-save-button">
                        {{ $getStringObj.getString($Strings.Mind_Group_Save) }}
                    </div>
                </div>

            </div>
            
        </div>
        <GroupHeaderImageModal :defaultHeaderList="defaultHeaderList" :show="showImageModal" @hiddenImageModal="hiddenImageModal"  />
        <GroupTagCreated v-if="showTag" :show="showTag" :defaultLabelList="defaultLabelList" @hiddenTap="hiddenTap" @selectedLabeList="selectedLabeList" />

    </div>
</template>

<script>
import { postGroupNew, postGroupListSystemGcovers,postGroupRecommendGtags } from "../../common/netWork/group_api";
import { mapMutations } from "vuex";
import httpImageToPrefix from '../../utils/httpImageToPrefix';
import GroupTagCreated from "./GroupTagCreated";
import GroupHeaderImageModal from "./groupCreatedChild/GroupHeaderImageModal"
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
export default {
    components: {
        GroupHeaderImageModal,
        GroupTagCreated
    },
    data() {
        return {
            userImage: '', //组头像信息
            groupName: '', //共享组名称
            groupNameWarning: '', //共享组名称
            groupIntroduce: '', // 共享组介绍
            groupIntroduceWarning: '', //共享组介绍
            groupTagList: null, //共享组标签
            showTag: false, //展示标签
            showImageModal: false, //展示图片模态框
            defaultHeaderList: [], //默认头像数组
            myTagList: [], //我的共享组的标签
            // allTagList: [], //所有共享组的标签
            groupNumber: true, //是否允许搜索组号
            addGroupMessage: true, //加入时是否需要验证消息
            groupToStorm: false, //是否分享组到风暴
            payToGoin: false, //是否付费加入
            mBeanNumber:0,//使用时需要支付的M豆数量
            mBeanList:[{
                label:5,
                value:5
            },{
                label:10,
                value:10
            }],
            defaultLabelList:[]//默认标签
        }
    },
    created() {
        this.pullDefaultCover({}).then(res => {
            this.defaultHeaderList = res.covers
            this.userImage = this.defaultHeaderList[0]
        })
    },
    methods: {
        ...mapMutations([
            "setRefreshMyGroupList",
        ]),
        getMBeanList(groupId){
            ajaxPromise(postGroupRecommendGtags,{groupId},this)
              .then((res) => {
                if (!!res) {
                    let mbeansOptions = res.mbeansOptions;//付费使用的M豆可选数量列表
                    if (!!mbeansOptions && mbeansOptions.length > 0) {
                        this.mBeanNumber = mbeansOptions[0];
                        this.mBeanList = mbeansOptions.map(item => {
                            return {
                                label : item,
                                value : item
                            }
                        })
                    }
                    let defaultTags = res.tags;//默认标签
                    if (!!defaultTags && defaultTags.length > 0) {
                        // let len = defaultTags.length;
                        // if (len > 5) {
                        //     defaultTags.length = 5 
                        // }
                        this.defaultLabelList = defaultTags;
                    }
                }
            })
        },
        checkSwitch(val) {
            this[val] = !this[val] 
            // this.settingFrome.groupId = this.groupDataDetail.id;
            // this.changeGroupSettings(this.settingFrome).then((res) => {
            // });
        }, 

        hidden(show) {
            if (show == true) {
                return;
            }
            let item = {
                show: false
            }
            this.$emit("hiddenAddModal", item);
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        //修改头像
        UpUserHeader() {
            this.pullDefaultCover({}).then(res => {
            this.defaultHeaderList = res.covers
            // this.userImage = this.defaultHeaderList[0]
        })
            this.showImageModal = true;
        },

        hiddenImageModal(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showImageModal = false;
                    this.userImage = obj.src;
                }
            }
        },

        clickCreatedTag() {
            this.showTag = true;
        },

        hiddenTap(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showTag = false;
                    if (obj.groupTagName != '') {
                        this.addGroupTag(obj.groupTagName);
                    }
                }
            }
        },
        selectedLabeList(obj){
            this.myTagList = [...this.myTagList,...obj]
            this.myTagList = Array.from(new Set(this.myTagList))//去掉重复项
        },
        addGroupTag(str) {
            if (typeof(str) !== 'string') {
                return
            }
            if (this.myTagList.indexOf(str) == -1) {
                this.myTagList.push(str);
            }
        },

        removeGroupTag(item,index) { //删除组内标签
            this.myTagList.splice(index,1);
        },

        saveGroupData() {
            let obj = {};
            this.groupName = this.trim(this.groupName) 
            if(this.groupName == '') {
                this.groupNameWarning = this.$getStringObj.getString(this.$Strings.Mind_Group_The_Group_Name_Cannot_Be_Empty);
            } else {
                let req = { 
                    cover: this.userImage,
                    name: this.groupName,
                    desc: this.groupIntroduce,
                    tags: this.myTagList,
                    canBeSearched: this.groupNumber,
                    needVerification: this.addGroupMessage,
                    isPublic: this.groupToStorm,
                    mbeansRequired: this.payToGoin ? this.mBeanNumber : 0
                }
                // console.log(req);
                this.pushGroupMessage(req).then((res) => {
                     this.setRefreshMyGroupList(true)
                     this.hidden();
                })
            }
        },

        inputArea() {   //输入组名
            if (this.groupName.length > 30){
                this.groupName = this.groupName.slice(0,30);
            }
            this.groupNameWarning = '';
        },

        inputIntroduce() {
            if (this.groupIntroduce.length > 120){
                this.groupIntroduce = this.groupIntroduce.slice(0,120);
            }
        },

        trim(str) { //判断有首尾空格去除空格
            return str.replace(/^\s+|\s+$/g, '');
        },

        pushGroupMessage(obj) {
            return new Promise((resolve, reject) => {
                postGroupNew(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        pullDefaultCover(obj) {
            return new Promise((resolve, reject) => {
                postGroupListSystemGcovers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        mBeanHandleChange(value) {
          this.mBeanNumber = value
        //   console.log(this.mBeanNumber);
        },
        seeExplain(){  
            const h = this.$createElement;
            this.$info({
                title: this.$getStringObj.getString(this.$Strings.Mind_Explain),
                content: h('div', {}, [
                  h('p', this.$getStringObj.getString(this.$Strings.Cloud_Group_Introduction)),
                  h('p', this.$getStringObj.getString(this.$Strings.Cloud_Group_Details)),
                ]),
                centered: true,
                okText:this.$getStringObj.getString(this.$Strings.Global_Ok),
                onOk() {
                },
            });
        }
    },
    watch:{
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                    this.getMBeanList(newVal.id)
                }
            },
            immediate: true,
            deep: true
        },
        //付费加入和验证消息只能有一个
        addGroupMessage(newVal){
            if (newVal) {
                this.payToGoin = false;
            }
        },
        payToGoin(newVal){
            if (newVal) {
                this.addGroupMessage = false;
                // this.groupToStorm = true;
            }
        },
        // groupToStorm(newVal){
        //   if (!newVal) {
        //     this.payToGoin = false;
        //   }
        // },
    }
}
</script>

<style lang="less" scoped>
    .group-created-new-group {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 570px;
            min-height: 500px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .close-modal:hover {
                background-color: #F2F2F2;
            }
            .group-add-header {
                margin: 20px auto;
                width: 88px;
                height: 88px;
                position: relative;
                background-color: #F2F2F2;
                border-radius: 5px;
                cursor: pointer;
                .level-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width : 44px;
                    height: 5px;
                    background-color: #999999;
                    border-radius: 2.5px;
                }
                .vertical-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 5px;
                    height: 44px;
                    background-color: #999999;
                    border-radius: 2.5px;
                }
            }
            .group-header-img {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .hiddenInput {
                display: none;
            }
            .group-detail-list {
                width: 530px;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 15px;
                .group-detail-name {
                    width: 80px;
                    display: inline-block;
                    margin-right: 20px;
                    text-align: right;
                }
                .group-detail-input {
                    width: 430px;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background-color: #F0F2F8;
                    outline: none;
                    border-radius: 2px;
                    font-size: 12px;
                    padding-left: 10px;
                }
                .group-name-warning {
                    color: #FD492B;
                    padding-left: 95px;
                }
            }

            .group-tag {
                width: 530px;
                margin: 0 auto;
                margin-bottom: 20px;
                display: flex;
                // justify-content: center;
                align-items: center;
                color: #333;
                .group-tag-name {
                    width: 80px;
                    text-align: right;
                    font-size: 14px;
                    margin-right: 20px;
                }
                .group-tag-right {
                    .group-my-tag-list {
                        width: 430px;
                        min-height: 38px;
                        // border: 1px solid #E2E2E2;
                        // background-color: #F0F2F8;
                        font-size: 14px;
                        border-radius: 2px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .group-my-tag-button {
                            display: flex;
                            align-items: center;
                            height: 28px;
                            margin: 5px 6px;
                            line-height: 28px;
                            padding: 0 12px;
                            background-color: rgba(236,112,90,.3);
                            border-radius: 14px;
                            cursor: pointer;
                            .group-my-tag-button-text {
                                // margin-right: 3px;
                                color: #EC705A;
                            }
                            .label-close-img{
                                width:16px;
                                margin-left: 8px;
                                opacity: .3;
                            }
                            // .group-my-tag-button-icon {
                            //     color: rgba(0,0,0,.1);
                            //     cursor: pointer;
                            // }
                        }

                        .group-all-tag-button {
                            display: inline-block;
                            height: 36px;
                            line-height: 36px;  
                            font-size: 14px;
                            padding: 0 18px; 
                            border-radius: 18px;
                            color: #333;
                            background-color: #F0F2F8;
                            margin-right: 15px;
                            margin-bottom: 4px;
                            cursor: pointer;
                        }
                    }
                }

            }
            .check-big-box {
                width: 570px;
                margin: 0 auto;
                border-top: 10px solid #F2F2F2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                padding-top: 15px;
                .check-big-box-child {
                    // margin: 0 20px;
                    color: #333;
                    height: 24px;
                    line-height: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .check-big-box-child-text {
                        margin-right: 10px;
                    }
                }
                .check-big-box-child:first-child{
                    width: 36%;
                }
                .check-big-box-child:last-child{
                    width: 60%;
                }
            } 
            .storm-big-box {
                // width: 570px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                padding-top: 15px;
                .storm-big-box-child {
                    // margin: 0 20px;
                    // width: 100%;
                    color: #333;
                    height: 24px;
                    line-height: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .storm-big-box-child-text {
                        margin-right: 25px;
                        .storm-text-link{
                            font-size: 14px;
                            color: #EC705A;
                            text-decoration: underline;
                            margin-left: 10px;
                        }
                    }
                    .m-bean-box{
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                        .m-bean-text{
                            font-size: 14px;
                            color: #333333;
                        }
                    }
                }
                .storm-big-box-child:first-child{
                    width: 36%;
                }
                .storm-big-box-child:last-child{
                    width: 60%;
                }
            }
            .group-save-button-box {
                margin-top: 30px;
                margin-bottom: 16px;
                text-align: center;
                .group-save-button {
                    display: inline-block;
                    margin: 0 auto;
                    font-size: 16px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 19px;
                    background-color: #FD492B;
                    color: #fff;
                    cursor: pointer;
                    position: relative;
                }
                .group-save-button:hover {
                    background-color: #FD492B;
                    color: #fff;
                }
                .group-save-button:active {
                    left: 1px;
                    top: 1px;
                }
            }

        }
        // min-height:calc(100vh - 80px); 
        // background-color: #fff;
        // .group-content {
        //     width: 100%;
        //     max-width: 1200px;
        //     margin: 0 auto;
        // }
    }


</style>